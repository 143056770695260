// (c) Cincom Systems, Inc. <2018> - <2024>
// ALL RIGHTS RESERVED
import {
  MODEL_DESIGNER_SET_MODELS,
  MODEL_DESIGNER_SET_CURRENT_MODEL,
  MODEL_DESIGNER_SET_CONSTRAINTS,
  MODEL_DESIGNER_SET_CURRENT_FORMULA,
  MODEL_DESIGNER_SET_CURRENT_MODEL_DETAILS,
  MODEL_DESIGNER_SET_CURRENT_MODEL_INFO,
  MODEL_DESIGNER_SET_CURRENT_CONSTRAINT,
  MODEL_DESIGNER_SET_OPTIONS_GROUPS,
  MODEL_DESIGNER_SET_METADATA_UIS,
  MODEL_DESIGNER_SET_CURRENT_GUIDED_UI,
  MODEL_DESIGNER_SET_GUIDED_UIS,
  MODEL_DESIGNER_SET_METADATA,
  MODEL_DESIGNER_SET_PARAMETERS,
  MODEL_DESIGNER_SET_BUNDLES,
  MODEL_DESIGNER_SET_CURRENT_PARAMETER,
  MODEL_DESIGNER_SET_CURRENT_VARIABLE,
  MODEL_DESIGNER_SET_VARIABLES,
  MODEL_DESIGNER_SET_BOM,
  MODEL_DESIGNER_SET_BOMS,
  MODEL_DESIGNER_SET_CURRENT_BOM,
  MODEL_DESIGNER_SET_BOM_ENTITIES,
  MODEL_DESIGNER_DECISION_RULES,
  MODEL_DESIGNER_SET_DECISION_RULES_SEQUENCE,
  MODEL_DESIGNER_SET_CURRENT_ENTITY,
  MODEL_DESIGNER_SET_ENTITIES,
  MODEL_DESIGNER_CONSTRAINT_SET_ACTIVE_STATUS,
  MODEL_DESIGNER_SET_SYSTEM_PROPERTIES,
  MODEL_DESIGNER_SET_PRICE_LISTS_PRECISION,
  SET_TEMP_SECTION_VISIBILITY_TABLE,
  SET_TEMP_RECOMMENDATIONS_TABLE,
  SET_TEMP_FILTERS_TABLE,
  SET_TEMP_SECTION_ENABLEMENT_TABLE,
  SET_TEMP_SECTION_REQUIREMENT_TABLE,
  SET_TEMP_VISIBILITY_TABLE,
  SET_TEMP_ENABLEMENT_TABLE,
  SET_TEMP_REQUIREMENT_TABLE,
  SET_TEMP_SPECIAL_REQUEST_TABLE,
  MODEL_DESIGNER_SET_FORMULAS,
  MODEL_DESIGNER_SET_FORMULAS_SEQUENCE,
  MODEL_DESIGNER_SET_INTEGRATIONS,
  MODEL_DESIGNER_SET_OUTPUTS_MODEL_ID,
  MODEL_DESIGNER_SET_LANGUAGES_MODEL_ID
} from '@/store/types'

import { getModelDesignerClient } from '@/api/modelDesignerApi'
import handleError from '@/api/handleError'
import * as Query from '@/utils/query'
import generateDecisionMatrixId from '@/utils/generateDecisionMatrixId'
import { addRowIdToDecisionMatrix } from '@/utils/ruleUtils'

const MODELS_API = 'models'
const ASSORTMENTS_API = 'assortments'
const PARAMETERS_API = 'parameters'
const INTEGRATIONS_API = 'integrations'

export default {
  // Outputs
  fetchOutputsModelId({ getters, commit }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/outputs`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_OUTPUTS_MODEL_ID, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // Languagess
  fetchLanguagesModelId({ getters, commit }) {
    return new Promise((resolve, reject) => {
      // getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/languages`)
      //   .then(response => {
      //     commit(MODEL_DESIGNER_SET_LANGUAGES_MODEL_ID, response.data)
      //     resolve(response.data)
      //   })
      //   .catch(error => {
      //     handleError(error)
      //     reject(error)
      //   })
      commit(MODEL_DESIGNER_SET_LANGUAGES_MODEL_ID, null)
      resolve(null)
    })
  },
  // Pricing
  syncAllPriceLists({ getters }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/price-lists/synchronize`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  saveSequence({ getters }, { modelId, payload, context }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`/tenants/${getters.getUserTenantId}/models/${modelId}/${context}/sequence`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPricingLinesModelId({ getters }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/lines`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPricingHeaderModelId({ getters }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/headers`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchLineModelIntegrations({ getters }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/lines/integrations`)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  fetchHeaderModelIntegrations({ getters }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/headers/integrations`)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  // Model Custom Fields
  fetchCustomFieldsByModel({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/custom-fields/get-all`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // Rule Contexts
  fetchRuleContext({ getters }, { modelId, ruleEditor, context }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/rule-editors/${ruleEditor}/contexts/${context}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchRuleContexts({ getters }, { modelId, ruleEditor }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/rule-editors/${ruleEditor}/contexts`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // Model Formulas
  validateFormula({ getters }, { modelId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas/validate`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchFormulas({ getters, commit }, { modelId, pageInfo, sortInfo, searchText = '' }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas?${query}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_FORMULAS, response.data.items)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchFormulasSequence({ getters, commit }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas/sequence`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_FORMULAS_SEQUENCE, response.data.sequences)
          resolve(response.data.sequences)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchFormulaById({ getters, commit }, { modelId, referenceId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas/${referenceId}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_FORMULA, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAllFormulaReferenceIds({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas/ids`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteFormulaById({ getters }, { modelId, formulaReferenceId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).delete(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas/${formulaReferenceId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteManyFormulas({ getters }, { modelId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas/delete-many`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addFormula({ getters }, { modelId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateFormula({ getters }, { modelId, referenceId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/formulas/${referenceId}`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // BOM
  fetchBom({ commit, getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/sales-bom`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_BOM, response.data.items)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBomEntities({ commit, getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/bom-entities`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_BOM_ENTITIES, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBomNestedEntities({ commit, getters }, { modelId, bomId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/boms/${bomId}/entities`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_BOM_ENTITIES, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBomNestedCustomEntities({ commit, getters }, { modelId, bomId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/boms/${bomId}/entities`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_BOM_ENTITIES, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBoms({ commit, getters }, { modelId, pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MODEL_DESIGNER_SET_BOMS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBomIds({ getters }, { modelId, searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBomTypes({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bom-types`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrentBom({ commit, getters }, { modelId, bomId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_BOM, response.data)
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateBom({ getters }, { modelId, bom }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/models/${modelId}/sales-bom`, bom)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateBomNested({ getters }, { modelId, bomId, bom }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/boms/${bomId}`, bom)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateBomNestedCustom({ getters }, { modelId, bomId, bom }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/boms/${bomId}`, bom)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateBomSettings({ getters }, { modelId, bomId, settings }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/boms/${bomId}/settings`, settings)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addBom({ getters }, { modelId, payload, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors && !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deleteBom({ getters }, { modelId, bomId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/boms/${bomId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteBoms({ dispatch }, { modelId, bomIds, tenantId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`tenants/${tenantId}/${MODELS_API}/${modelId}/boms/delete-many`, bomIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // DECISION RULES
  fetchDecisionRules({ commit, getters }, { modelId, pageInfo, sortInfo, searchText = '', commitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/decision-rules?${query}`)
        // getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/decisions/Metadata?${query}`)
        .then(response => {
          if (commitToStore) commit(MODEL_DESIGNER_DECISION_RULES, response.data.items)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchDecisionRulesSequence({ getters, commit }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/decision-rules/sequence`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_DECISION_RULES_SEQUENCE, response.data.sequences)
          resolve(response.data.sequences)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchDecisionRuleIds({ getters }, { modelId, searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/decision-rules/get-ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteDecisionRule({ getters }, { modelId, entityId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/decision-rules/${entityId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteDecisionRules({ getters }, { modelId, entityIds }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`tenants/${getters.getUserTenantId}/models/${modelId}/decision-rules/delete-many`, entityIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // MODELS
  fetchModelName({ getters }, { name }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`/tenants/${getters.getUserTenantId}/models/name/${name}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  getOptionsByGroupId({ getters }, { modelId, groupId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/option-groups/${groupId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchGroupsByModel({ commit, getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/option-groups`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_OPTIONS_GROUPS, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchConstraintMatrixById({ commit, getters }, { modelId, constraintId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/matrix/${constraintId}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_CONSTRAINT, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchRelatedModels({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/relationships`)
        .then(({
          data
        }) => resolve(data))
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  saveRelatedModel({ getters }, { modelId, ids }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/relationships`, {
        ids
      })
        .then(({ data }) => resolve(data))
        .catch(err => {
          handleError(err)
          reject(err)
        })
    })
  },
  fetchModels({ getters, commit }, { pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MODEL_DESIGNER_SET_MODELS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchModelIds({ getters }, { searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchModel({ commit, getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/assortments`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_MODEL, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchModelDetails({ commit, getters }, { modelId, commitToStore = true }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}`)
        .then(response => {
          if (commitToStore) {
            commit(MODEL_DESIGNER_SET_CURRENT_MODEL_DETAILS, response.data)
          }
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchModelInfo({ commit, getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/basic-info`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_MODEL_INFO, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addModel({ getters }, { model, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}`, model)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  updateModel({ commit, dispatch, getters }, { modelId, payload, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}`, payload)
        .then(response => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deleteModel({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteModels({ getters }, { modelIds }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/delete-many`, modelIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchEntityById({ commit, getters }, { modelId, entityId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`/tenants/${getters.getUserTenantId}/models/${modelId}/entities/${entityId}`)
        .then(response => {
          if (response.data.objectType.toLowerCase().includes('group')) {
            if (response.data.products) {
              response.data.products = response.data.products.map(i => ({ ...i, value: i.name }))
            }
            if (response.data.options) {
              response.data.options = response.data.options.map(i => ({ ...i, value: i.name }))
            }
            if (response.data.bomItems) {
              response.data.bomItems = response.data.bomItems.map(i => ({ ...i, value: i.name }))
            }
          }
          commit(MODEL_DESIGNER_SET_CURRENT_ENTITY, response.data)
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchEntitiesByModel({ commit, getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`/tenants/${getters.getUserTenantId}/models/${modelId}/entities`)
        .then(response => {
          response.data = [...response.data].map(e => e.objectType.toLowerCase().includes('group') ? { ...e, value: e.name } : e)
          commit(MODEL_DESIGNER_SET_ENTITIES, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchGroupsUsedInModel({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`/tenants/${getters.getUserTenantId}/models/${modelId}/groups`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchConfiguredGroupsUsedInModel({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`/tenants/${getters.getUserTenantId}/models/${modelId}/configured-product-groups`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchSubModels({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/sub-models/${modelId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  publishModel({ commit, getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/publish/major`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_MODEL, response.data)
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  syncModel({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/sync`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // MODEL UI
  fetchMetadataUis({ getters, commit }, { modelId, pageInfo, sortInfo, searchText = '' }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/metadata-definitions?${query}`)
        .then(({ data }) => {
          commit(MODEL_DESIGNER_SET_METADATA_UIS, data.items)
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchMetadata({ commit, getters }, { modelId, metadataType }) {
    return new Promise((resolve, reject) => {
      const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
      getModelDesignerClient(SRE ? 3.0 : 2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_METADATA, response.data)
          if (SRE) {
            response.data.sections = response.data.sections?.map(section => {
              section.controlIds = section.controls?.map(control => control.id)
              section.bundles = section.bundles?.map(bundle => {
                bundle.groupIds = bundle.groups?.map(group => group.id)
                return bundle
              })
              return section
            })
            response.data.steps = response.data.steps?.map(step => {
              step.sections = step.sections?.map(section => {
                section.controlIds = section.controls?.map(control => control.id)
                section.bundles = section.bundles?.map(bundle => {
                  bundle.groupIds = bundle.groups?.map(group => group.id)
                  return bundle
                })
                return section
              })
              return step
            })
            commit(MODEL_DESIGNER_SET_METADATA, response.data)
          }
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchMetadataPricing({ commit, getters }, { modelId, metadataType }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}/pricing`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_METADATA, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchMetadataSection({ commit, getters }, { modelId, metadataType, sectionId }) {
    return new Promise((resolve, reject) => {
      const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
      getModelDesignerClient(SRE ? 3.0 : 2.0).get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/metadata/${metadataType}/sections/${sectionId}`)
        .then(response => {
          if (SRE) {
            response.data.expanded = response.data.isExpanded
          }
          // flag for backend support
          const { visibility, enablement, requirement } = response.data
          if (visibility) {
            visibility.rows = addRowIdToDecisionMatrix(visibility.rows)
            if (visibility.rows.length === 0 && visibility.conditions.length === 0) {
              visibility.rows.push({
                outcome: true,
                conditions: [],
                rowId: generateDecisionMatrixId.getId()
              })
            }
            commit(SET_TEMP_SECTION_VISIBILITY_TABLE, visibility)
          }
          if (enablement) {
            enablement.rows = addRowIdToDecisionMatrix(enablement.rows)
            if (enablement.rows.length === 0 && enablement.conditions.length === 0) {
              enablement.rows.push({
                outcome: true,
                conditions: [],
                rowId: generateDecisionMatrixId.getId()
              })
            }
            commit(SET_TEMP_SECTION_ENABLEMENT_TABLE, enablement)
          }
          if (requirement) {
            requirement.rows = addRowIdToDecisionMatrix(requirement.rows)
            if (requirement.rows.length === 0 && requirement.conditions.length === 0) {
              requirement.rows.push({
                outcome: false,
                conditions: [],
                rowId: generateDecisionMatrixId.getId()
              })
            }
            commit(SET_TEMP_SECTION_REQUIREMENT_TABLE, requirement)
          }
          if (requirement) {
            // Backend Support
            // if (specialRequest) {
            // specialRequest.rows = addRowIdToDecisionMatrix(specialRequest)
            // if (specialRequest.rows.length === 0 && specialRequest.conditions.length === 0) {
            //   specialRequest.rows.push({
            //     outcome: false,
            //     conditions: [],
            //     rowId: generateDecisionMatrixId.getId()
            //   })
            // }
            // commit(SET_TEMP_SPECIAL_REQUEST_TABLE, specialRequest)
            commit(SET_TEMP_SPECIAL_REQUEST_TABLE, requirement)
          }
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchMetadataControl({ commit, getters }, { modelId, metadataType, sectionId, entityId }) {
    return new Promise((resolve, reject) => {
      const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
      getModelDesignerClient(SRE ? 3.0 : 2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}/sections/${sectionId}/controls/${entityId}`)
        .then(response => {
          const { visibility, enablement, requirement } = response.data
          visibility.rows = addRowIdToDecisionMatrix(visibility.rows)
          enablement.rows = addRowIdToDecisionMatrix(enablement.rows)
          requirement.rows = addRowIdToDecisionMatrix(requirement.rows)

          // if rows are empty, all values are selected so make an empty row to force all values state
          if (visibility.rows.length === 0 && visibility.conditions.length === 0) {
            visibility.rows.push({
              outcome: true,
              conditions: [],
              rowId: generateDecisionMatrixId.getId()
            })
          }
          if (enablement.rows.length === 0 && enablement.conditions.length === 0) {
            enablement.rows.push({
              outcome: true,
              conditions: [],
              rowId: generateDecisionMatrixId.getId()
            })
          }
          if (requirement.rows.length === 0 && requirement.conditions.length === 0) {
            requirement.rows.push({
              outcome: false,
              conditions: [],
              rowId: generateDecisionMatrixId.getId()
            })
          }

          // Backend Support
          // if (requirement.rows.length === 0 && requirement.conditions.length === 0) {
          //   requirement.rows.push({
          //     outcome: false,
          //     conditions: [],
          //     rowId: generateDecisionMatrixId.getId()
          //   })
          // }

          commit(SET_TEMP_VISIBILITY_TABLE, response.data.visibility)
          commit(SET_TEMP_ENABLEMENT_TABLE, response.data.enablement)
          commit(SET_TEMP_REQUIREMENT_TABLE, response.data.requirement)
          // Backend Support
          commit(SET_TEMP_SPECIAL_REQUEST_TABLE, response.data.requirement)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchMetadataSettings({ commit, getters }, { modelId, metadataType }) {
    return new Promise((resolve, reject) => {
      const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
      getModelDesignerClient(SRE ? 3.0 : 2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}/settings`)
        .then(response => {
          if (SRE) response.data.defaultViewType = response.data.viewType
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateMetadata({ getters }, { modelId, metadataType, payload }) {
    const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
    if (SRE) {
      payload.sections = payload.sections?.map(section => {
        // eslint-disable-next-line
        section.controls = section.controlIds?.map(id => { return { id: id } })
        section.bundles = section.bundles?.map(bundle => {
          // eslint-disable-next-line
          bundle.groups = bundle.groupIds?.map(id => { return { id: id } })
          return bundle
        })
        return section
      })
      payload.steps = payload.steps?.map(step => {
        step.sections = step.sections?.map(section => {
          // eslint-disable-next-line
          section.controls = section.controlIds?.map(id => { return { id: id } })
          section.bundles = section.bundles?.map(bundle => {
            // eslint-disable-next-line
            bundle.groups = bundle.groupIds?.map(id => { return { id: id } })
            return bundle
          })
          return section
        })
        return step
      })
    }
    return new Promise((resolve, reject) => {
      getModelDesignerClient(SRE ? 3.0 : 2.0).put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/metadata/${metadataType}`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateMetadataPricing({ getters }, { modelId, metadataType, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/metadata/${metadataType}/pricing`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateMetadataSection({ getters }, { allowManual, modelId, metadataType, sectionId, sectionName, sectionType, controlIds, expanded, guidedSellingId, isEnabled, isRequired, isVisible, allowSpecialRequest, relationshipBehavior }) {
    const visibility = getters.getTempSectionVisibilityTable
    const enablement = getters.getTempSectionEnablementTable
    const requirement = getters.getTempSectionRequirementTable
    const payload = {
      allowManual,
      name: sectionName,
      sectionType: sectionType,
      visibility: { ...visibility, rows: visibility.rows },
      enablement: { ...enablement, rows: enablement.rows },
      requirement: { ...requirement, rows: requirement.rows },
      allowSpecialRequest: allowSpecialRequest,
      relationshipBehavior: relationshipBehavior,
      controlIds,
      expanded,
      guidedSellingId,
      isEnabled,
      isRequired,
      isVisible
    }
    if (sectionId === null) {
      return new Promise((resolve, reject) => {
        const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
        getModelDesignerClient(SRE ? 3.0 : 2.0).post(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}/sections`, payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            handleError(error)
            reject(error)
          })
      })
    }
    return new Promise((resolve, reject) => {
      const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
      if (SRE) {
        payload.isExpanded = payload.expanded
      }
      getModelDesignerClient(SRE ? 3.0 : 2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}/sections/${sectionId}`, {
        ...payload,
        id: sectionId
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response.data.errors[0].code === 1503) {
            resolve()
          } else {
            handleError(error)
            reject(error)
          }
        })
    })
  },
  updateMetadataControl({ getters }, { modelId, metadataType, entityId, sectionId, controlType, decimalPlaces, displayFormat, guidedSellingId, isEnabled, isRequired, isVisible, allowSpecialRequest, listDisplayFormat, onChangeDecisionRuleIds, autoRefreshLines = false, displayAsCurrency = false }) {
    const visibility = getters.getTempVisibilityTable
    const enablement = getters.getTempEnablementTable
    const requirement = getters.getTempRequirementTable
    const payload = {
      controlType,
      decimalPlaces,
      displayFormat,
      guidedSellingId,
      listDisplayFormat,
      onChangeDecisionRuleIds,
      isEnabled,
      isRequired,
      isVisible,
      autoRefreshLines,
      displayAsCurrency,
      enablement: { ...enablement, rows: enablement.rows },
      requirement: { ...requirement, row: requirement.rows },
      visibility: { ...visibility, rows: visibility.rows },
      allowSpecialRequest: allowSpecialRequest
    }
    return new Promise((resolve, reject) => {
      const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
      getModelDesignerClient(SRE ? 3.0 : 2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}/sections/${sectionId}/controls/${entityId}`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateMetadataSettings({ getters }, { modelId, metadataType, payload }) {
    const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
    if (SRE) payload.viewType = payload.defaultViewType
    return new Promise((resolve, reject) => {
      getModelDesignerClient(SRE ? 3.0 : 2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}/settings`, payload)
        .then(response => {
          if (SRE) response.data.defaultViewType = response.data.viewType
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updatePackageSection({ getters }, { modelId, metadataType, payload }) {
    return new Promise((resolve, reject) => {
      const SRE = getters.flags_getAllFlags.find(flag => flag.name === 'SRE')?.isEnabled
      getModelDesignerClient(SRE ? 3.0 : 2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/metadata/${metadataType}/sections/${payload.sectionId}`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // GUIDED SELLING
  fetchGuidedUIs({ getters, commit }, { modelId, pageInfo, sortInfo, searchText = '' }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`/tenants/${getters.getUserTenantId}/models/${modelId}/metadata/guided-selling?${query}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_GUIDED_UIS, response.data.items)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addGuidedUI({ getters }, { modelId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`/tenants/${getters.getUserTenantId}/models/${modelId}/metadata/guided-selling`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteGuidedUIs({ getters }, { modelId, guidedIds }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`/tenants/${getters.getUserTenantId}/models/${modelId}/metadata/guided-selling/delete-many`, guidedIds)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchGuidedUI({ getters, commit }, { modelId, id }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`/tenants/${getters.getUserTenantId}/models/${modelId}/metadata/guided-selling/${id}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_GUIDED_UI, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateGuidedUI({ getters }, { modelId, id }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`/tenants/${getters.getUserTenantId}/models/${modelId}/metadata/guided-selling/${id}`, getters.getCurrentGuidedUi)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteGuidedUI({ getters }, { modelId, id }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).delete(`/tenants/${getters.getUserTenantId}/models/${modelId}/metadata/guided-selling/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // DEMO MODELS
  fetchDemoModels({ commit }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get('demos')
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // MODEL COLLECTIONS
  fetchModelCollections({ getters }, { modelId, usage }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`/tenants/${getters.getUserTenantId}/models/${modelId}/collections/${usage}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // MODEL CONSTRAINTS
  fetchConstraints({ commit, getters }, { modelId, pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`/tenants/${getters.getUserTenantId}/models/${modelId}/constraints?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MODEL_DESIGNER_SET_CONSTRAINTS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchConstraintIds({ getters }, { modelId, searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchConstraintById({ getters }, { modelId, constraintId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/${constraintId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addConstraint({ getters }, { modelId, payload, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  enableConstraint({ commit, getters }, { modelId, constraintId }) {
    return getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/${constraintId}/enable`)
      .then(() => {
        commit(MODEL_DESIGNER_CONSTRAINT_SET_ACTIVE_STATUS, {
          isEnabled: true,
          constraintId
        })
      })
      .catch(error => {
        handleError(error)
      })
  },
  disableConstraint({ commit, getters }, { modelId, constraintId }) {
    return getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/${constraintId}/disable`)
      .then(() => {
        commit(MODEL_DESIGNER_CONSTRAINT_SET_ACTIVE_STATUS, {
          isEnabled: false,
          constraintId
        })
      })
      .catch(error => {
        handleError(error)
      })
  },
  deleteConstraint({ getters }, { modelId, constraintId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/${constraintId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteConstraints({ getters }, { modelId, constraintIds }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/delete-many`, constraintIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateConstraint({ getters }, { modelId, constraintId, modelConstraint }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/matrix/${constraintId}`, modelConstraint)
        .then(response => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateConstraintHeader({ commit, getters }, { modelId, constraintId, data, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().patch(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/constraints/${constraintId}/header`, data)
        .then(response => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  /// ASSORTMENTS
  updateAssortments({ getters }, { assortments, modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/${ASSORTMENTS_API}`, assortments)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  importAssortments({ getters }, { modelId, excelFile }) {
    const formData = new FormData()
    formData.append('file', excelFile)
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/${ASSORTMENTS_API}/import`, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          // handleError(error)
          reject(error)
        })
    })
  },
  downloadAssortmentsExcelBlob({ getters }, modelId) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/${ASSORTMENTS_API}/export`, {
        responseType: 'blob'
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  /// MODEL BUNDLES
  addBundle({ commit, getters }, { modelId, payload, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles`, payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  fetchBundles({ commit, getters }, { modelId, pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MODEL_DESIGNER_SET_BUNDLES, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  duplicateBundle({ getters }, { modelId, bundleId }) {
    return new Promise((resolve, reject) => {
      // TODO Check Route when BE finished
      getModelDesignerClient(2.0).post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles/duplicate/${bundleId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBundleDetail({ getters }, { modelId, bundleId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles/${bundleId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBundleIds({ getters }, { modelId, searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchBundleDefaults({ getters }, modelId) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundle-defaults`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteBundle({ getters }, { modelId, bundleId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles/${bundleId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteBundles({ dispatch }, { modelId, bundleIds, tenantId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).post(`tenants/${tenantId}/${MODELS_API}/${modelId}/bundles/delete-many`, bundleIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateBundle({ getters }, { modelId, bundleId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles/${bundleId}`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateBundleSettings({ getters }, { modelId, bundleId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundles/${bundleId}/settings`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateBundleDefaults({ getters }, { modelId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/bundle-defaults`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  /// MODEL VARIABLES
  fetchVariables({ commit, getters }, { modelId, pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/variables?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MODEL_DESIGNER_SET_VARIABLES, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchVariableIds({ getters }, { modelId, searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/variables/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrentVariable({ commit, getters }, { modelId, variableId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`/tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/variables/${variableId}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_VARIABLE, response.data)
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addVariable({ getters }, { dataType, modelId, payload, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/variables/${dataType}`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deleteVariable({ getters }, { modelId, variableId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().delete(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/variables/${variableId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteVariables({ dispatch }, { modelId, variableIds, tenantId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${tenantId}/${MODELS_API}/${modelId}/variables/delete-many`, variableIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateVariable({ getters }, { dataType, variableId, modelId, payload, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().put(`tenants/${getters.getUserTenantId}/${MODELS_API}/${modelId}/variables/${dataType}/${variableId}`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  // PARAMETERS
  fetchParameters({ commit, getters }, { pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${PARAMETERS_API}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MODEL_DESIGNER_SET_PARAMETERS, data.items.map(i => ({ ...i.entity, parameterType: i.parameterType })))
          }
          resolve({ ...data, items: data.items.map(i => ({ ...i.entity, parameterType: i.parameterType })) })
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchParameterIds({ getters }, { searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${PARAMETERS_API}/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrentParameter({ getters, commit }, { parameterId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${PARAMETERS_API}/${parameterId}`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_CURRENT_PARAMETER, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addParameter({ getters }, { parameterData, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${PARAMETERS_API}/${parameterData.dataType.toLowerCase()}`, parameterData.payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  updateParameter({ getters }, { parameterData, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().put(`tenants/${getters.getUserTenantId}/${PARAMETERS_API}/${parameterData.dataType.toLowerCase()}/${parameterData.parameterId}`, parameterData.payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deleteParameter({ getters }, { parameterId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().delete(`tenants/${getters.getUserTenantId}/${PARAMETERS_API}/${parameterId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteParameters({ getters }, { parameterIds }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${PARAMETERS_API}/delete-many`, parameterIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // INTEGRATIONS
  fetchIntegrations({ commit, getters }, { pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${INTEGRATIONS_API}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore)
            commit(MODEL_DESIGNER_SET_INTEGRATIONS, data.items)

          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchIntegrationById({ getters }, { integrationId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${INTEGRATIONS_API}/${integrationId}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchIntegrationIds({ getters }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/${INTEGRATIONS_API}/ids`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addIntegration({ getters }, { integration, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${INTEGRATIONS_API}`, integration)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  updateIntegration({ getters }, { integration, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().put(`tenants/${getters.getUserTenantId}/${INTEGRATIONS_API}/${integration.id}`, integration)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deleteIntegration({ getters }, { integrationId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().delete(`tenants/${getters.getUserTenantId}/${INTEGRATIONS_API}/${integrationId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteIntegrations({ getters }, { integrationIds }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/${INTEGRATIONS_API}/delete-many`, integrationIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // INTEGRATION MAPPINGS
  fetchIntegrationMappings({ getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/integration-mappings`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateIntegrationMappings({ getters }, { modelId, payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/integration-mappings`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // RELATIONSHIPS
  fetchRelationship({ getters, commit }, { modelId, relationshipId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).get(`tenants/${getters.getUserTenantId}/models/${modelId}/relationships/${relationshipId}/settings`)
        .then(response => {
          const { visibility, recommend, filter } = response.data
          visibility.rows = addRowIdToDecisionMatrix(visibility.rows)
          recommend.rows = addRowIdToDecisionMatrix(recommend.rows)
          filter.rows = addRowIdToDecisionMatrix(filter.rows)

          // if rows are empty, all values are selected so make an empty row to force all values state
          if (visibility.rows.length === 0 && visibility.conditions.length === 0) {
            visibility.rows.push({
              outcome: true,
              conditions: [],
              rowId: generateDecisionMatrixId.getId()
            })
          }

          if (recommend.rows.length === 0 && recommend.conditions.length === 0) {
            recommend.rows.push({
              outcomes: [
                { values: [], outcomeType: 'Value', dataType: 'List', binding: null, quantity: 1, quantityType: 'Value', quantityBinding: null }
              ],
              conditions: [],
              rowId: 0
            })
          }

          if (filter.rows.length === 0 && filter.conditions.length === 0) {
            filter.rows.push({
              outcomes: [
                { values: [], outcomeType: 'Value', dataType: 'List', binding: null, quantity: 1, quantityType: 'Value', quantityBinding: null }
              ],
              conditions: [],
              rowId: 0
            })
          }

          commit(SET_TEMP_VISIBILITY_TABLE, visibility)
          commit(SET_TEMP_RECOMMENDATIONS_TABLE, recommend)
          commit(SET_TEMP_FILTERS_TABLE, filter)

          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateRelationship({ getters }, { modelId, relationshipId, payload, ignoreErrorCodes = [] }) {
    const updatedPayload = {
      ...payload,
      filter: getters.getTempFiltersTable,
      recommend: getters.getTempRecommendationsTable,
      enablement: getters.getTempEnablementTable,
      visibility: getters.getTempVisibilityTable
    }

    return new Promise((resolve, reject) => {
      getModelDesignerClient(2.0).put(`tenants/${getters.getUserTenantId}/models/${modelId}/relationships/${relationshipId}/settings`, updatedPayload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  // SYSTEM PROPERTIES
  fetchSystemProperties({ commit, getters }, { modelId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/models/${modelId}/configuration/system-properties`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_SYSTEM_PROPERTIES, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // ENVIRONMENTS
  fetchEnvironments({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/environments`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  promoteFromDevelopment({ getters }, { payload }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/environments`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  promoteFromEnvironment({ getters }, { environmentId }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().post(`tenants/${getters.getUserTenantId}/environments/${environmentId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPrecision({ getters, commit }) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().get(`tenants/${getters.getUserTenantId}/precision`)
        .then(response => {
          commit(MODEL_DESIGNER_SET_PRICE_LISTS_PRECISION, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updatePrecision({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      getModelDesignerClient().put(`tenants/${getters.getUserTenantId}/precision`, payload)
        .then((response) => {
          commit(MODEL_DESIGNER_SET_PRICE_LISTS_PRECISION, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}
