// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED                      
import handleError from '@/api/handleError'

const actions = {
  createSupportTicket({ getters }, { payload }) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('xnQsjsdp', 'edbsn75c56c01ea10bb90096336d9fa478f0a')
      formData.append('xmIwtLD', 'edbsnceb507208d1726c594e950d492ae02895bd1865c22fe50fb29bdedaf8b0f0d7e')
      formData.append('xJdfEaS', '')
      formData.append('actionType', 'Q2FzZXM=')
      formData.append('returnURL', '*')
      formData.append('Company', payload.Company)
      formData.append('Contact Name', payload.LName)
      formData.append('First Name', payload.FName)
      formData.append('Email', payload.Email)
      formData.append('Phone', payload.Phone)
      formData.append('Selected Topic', payload['Selected Product'])
      formData.append('Selected Product', 'Cincom CPQ')
      formData.append('Severity', payload.Severity)
      formData.append('Classification', 'Problem')
      formData.append('Case Origin', 'Product')
      formData.append('Subject', payload.Subject)
      formData.append('Description', payload.Description)
    
      return fetch('https://support.cincom.com/support/WebToCase', {
        method: 'POST',
        mode: 'no-cors',
        referrerPolicy: 'no-referrer',
        body: formData
      }).then(res => {
        resolve(res.data)
      })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    }) 
  }
}

export default {
  actions
}
