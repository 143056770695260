// (c) Cincom Systems, Inc. <2018> - <2023>
// ALL RIGHTS RESERVED
import { getMasterDataClient } from '@/api/masterDataApi'
import handleError from '@/api/handleError'
import * as Query from '@/utils/query'
import {
  MASTER_DATA_SET_CURRENCIES,
  MASTER_DATA_SET_CUSTOM_FIELDS,
  MASTER_DATA_SET_CURRENT_CUSTOM_FIELD,
  MASTER_DATA_SET_PRICED_ITEMS,
  MASTER_DATA_SET_CURRENT_PRICED_ITEM,
  MASTER_DATA_SET_UNASSIGNED_PRICED_ITEMS,
  MASTER_DATA_SET_PRICE_LISTS,
  MASTER_DATA_SET_CURRENT_PRICE_LIST,
  MASTER_DATA_SET_PRICE_LIST_ITEMS,
  MASTER_DATA_SET_PRICE_SHEET_ITEMS,
  MASTER_DATA_SET_COST_SHEET_ITEMS,
  MASTER_DATA_SET_GROUPS,
  MASTER_DATA_SET_COLUMN_SETTINGS,
  MASTER_DATA_SET_CURRENT_GROUP
} from '@/store/types'
import { PRECISION_TYPES } from '@/common/constants/precisionTypes'

const CURRENCIES_API = 'currencies'
const CUSTOM_FIELDS_API = 'custom-fields'
const PRICE_LISTS_API = 'price-lists'
const PRICE_LISTS_API_NEW = 'pricing-sheets'
const PRICE_SHEET_API = 'price-sheets'
const COST_SHEET_API = 'cost-sheets'
const PRICED_ITEMS_API = 'priced-items'
const GROUPS_API = 'groups'
const IMAGES_API = 'images'
const COLUMNS_SETTINGS_API = 'column-settings'

export default {
  // CURRENCIES
  fetchCurrencyById({ commit, getters }, { currencyId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${CURRENCIES_API}/${currencyId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrencies({ commit, getters }, { pageInfo = null, sortInfo = null, searchText = '' } = {}) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${CURRENCIES_API}?${query}`)
        .then(({ data }) => {
          data.items = data.items.map(item => {
            item.precision = PRECISION_TYPES.find(type => type.beName === item.precision).name
            return item
          })
          commit(MASTER_DATA_SET_CURRENCIES, data.items || data)
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchAvailableCurrencies({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${CURRENCIES_API}/available`)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addCurrency({ commit, getters }, { payload, ignoreErrorCodes = [] }) {
    const beName = PRECISION_TYPES.find(type => type.name.toLowerCase().replace(/\s/g, '') === payload.precision.toLowerCase().replace(/\s/g, '')).beName
    const currency = { ...payload, precision: beName }
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${CURRENCIES_API}`, currency)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          if (!ignoreErrorCodes.includes(error.response?.data?.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deleteCurrency({ getters }, { currencyId, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${CURRENCIES_API}/${currencyId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (!ignoreErrorCodes.includes(error.response?.data?.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deleteCurrencies({ getters }, { currencyIds }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${CURRENCIES_API}/delete-many`, currencyIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateCurrency({ getters }, { payload, ignoreErrorCodes = [] }) {
    const beName = PRECISION_TYPES.find(type => type.name.toLowerCase().replace(/\s/g, '') === payload.precision.toLowerCase().replace(/\s/g, '')).beName
    const currency = { ...payload, precision: beName }
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${CURRENCIES_API}/${currency.id}`, currency)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          if (!ignoreErrorCodes.includes(error.response?.data?.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  // CUSTOM FIELDS
  fetchCustomFields({ commit, getters }, { pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${CUSTOM_FIELDS_API}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_CUSTOM_FIELDS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCustomFieldsByPricedItemType({ getters }, entityType) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${CUSTOM_FIELDS_API}/priced-item-type/${entityType}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCustomFieldIds({ getters }, { searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${CUSTOM_FIELDS_API}/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrentCustomField({ commit, getters }, { customFieldId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${CUSTOM_FIELDS_API}/${customFieldId}`)
        .then(response => {
          commit(MASTER_DATA_SET_CURRENT_CUSTOM_FIELD, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addCustomField({ getters }, { customField, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${CUSTOM_FIELDS_API}`, customField)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  updateCustomField({ getters }, { customField, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${CUSTOM_FIELDS_API}/${customField.id}`, customField)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deleteCustomField({ getters }, { customFieldId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${CUSTOM_FIELDS_API}/${customFieldId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteCustomFields({ getters }, { customFieldIds }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${CUSTOM_FIELDS_API}/delete-many`, customFieldIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // PRICED ITEMS
  fetchPricedItems({ getters, commit }, { pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_PRICED_ITEMS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPricedItemsByType({ getters }, { pricedItemType, searchText = '' }) {
    let queryObject = {}
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/get-by-type/${pricedItemType}?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPricedItemsLight({ getters }, { pricedItemType, searchText = '' }) {
    let queryObject = {}
    if (pricedItemType) queryObject.pricedItemType = pricedItemType
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/light?${query}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPricedItemIds({ getters }, { searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrentPricedItem({ commit, getters }, { pricedItemId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient(2.0).get(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/${pricedItemId}`)
        .then(response => {
          commit(MASTER_DATA_SET_CURRENT_PRICED_ITEM, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchUnassignedPricedItems({ commit, getters }, { modelType }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient(2.0).get(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/unassigned/${modelType}`)
        .then(response => {
          commit(MASTER_DATA_SET_UNASSIGNED_PRICED_ITEMS, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addPricedItem({ getters }, { pricedItem, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}`, pricedItem)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  copyPricedItem({ getters }, { pricedItemId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/duplicate/${pricedItemId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updatePricedItem({ getters }, { pricedItem, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/${pricedItem.id}`, pricedItem)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  deletePricedItem({ getters }, { pricedItemId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/${pricedItemId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deletePricedItems({ getters }, { pricedItemIds }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/delete-many`, pricedItemIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  importPricedItems({ getters, dispatch }, { excelFile }) {
    const formData = new FormData()
    formData.append('file', excelFile)
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`/tenants/${getters.getUserTenantId}/import/priced-items`, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          // handleError(error)
          reject(error)
        })
    })
  },
  downloadPricedItemExcelEmptyTemplate({ getters }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient()
        .get(`tenants/${getters.getUserTenantId}/templates/priced-items`, {
          responseType: 'blob'
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  downloadPricedItemsExcelBlob({ getters }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`/tenants/${getters.getUserTenantId}/export/priced-items`, {
        responseType: 'blob'
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  downloadSelectedPricedItemsExcelBlob({ getters }, { selectedPricedItems }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`/tenants/${getters.getUserTenantId}/export/priced-items`, selectedPricedItems, {
        responseType: 'blob'
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updatePricedItemOutputSequence({ getters }, { pricedItem }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICED_ITEMS_API}/${pricedItem.id}/output-sequence`, { outputSequence: pricedItem.outputSequence })
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // PRICE LISTS
  fetchPriceLists({ getters, commit }, { pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_PRICE_LISTS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPriceListIds({ getters }, { searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrentPriceList({ getters, commit }, { priceListId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${priceListId}`)
        .then(response => {
          commit(MASTER_DATA_SET_CURRENT_PRICE_LIST, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addPriceList({ getters }, { priceList }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}`, priceList)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updatePriceList({ getters }, { priceList }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${priceList.id}`, priceList)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deletePriceList({ getters }, { priceListId }) {
    return new Promise(resolve => {
      getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${priceListId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
        })
    })
  },
  deletePriceLists({ getters }, { priceListIds }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/delete-many`, priceListIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deletePriceSheet({ getters }, { priceSheetId }) {
    return new Promise(resolve => {
      getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/${priceSheetId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
        })
    })
  },
  deletePriceSheets({ getters }, { priceSheetIds }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/delete-many`, priceSheetIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchDefaultPriceLists({ getters, commit }) {
    let queryObject = { PageNumber: 1, PageSize: 100, SortBy: 'name', SortDirection: 'descending', searchText: 'Default' }
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}?${Query.qs(queryObject)}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPriceListsNew({ getters, commit }, { pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_PRICE_LISTS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPriceListIdsNew({ getters }, { searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCurrentPriceListNew({ getters, commit }, { priceListId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/${priceListId}`)
        .then(response => {
          commit(MASTER_DATA_SET_CURRENT_PRICE_LIST, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addPriceListNew({ getters }, { priceList }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}`, priceList)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updatePriceListNew({ getters }, { priceList }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/${priceList.id}`, priceList)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchCurrentCostList({ getters, commit }, { costListId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${costListId}`)
        .then(response => {
          commit(MASTER_DATA_SET_CURRENT_PRICE_LIST, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addCostList({ getters }, { priceList }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}`, priceList)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateCostList({ getters }, { priceList }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${priceList.id}`, priceList)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // PRICE LIST ITEMS
  downloadPriceListItemsExcelEmptyTemplate({ getters }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/templates/price-list-items`, {
        responseType: 'blob'
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  downloadPriceListItemsExcelBlob({ getters }, priceListId) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/export/${PRICE_LISTS_API}/${priceListId}/priced-items`, {
        responseType: 'blob'
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  downloadPriceSheetItemsExcelBlob({ getters }, priceSheetId) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/${priceSheetId}/export`, {
        responseType: 'blob'
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  downloadAllPriceSheetsExcelBlob({ getters }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/export`, {
        responseType: 'blob'
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  importPriceListItems({ getters }, { priceListId, excelFile }) {
    const formData = new FormData()
    formData.append('file', excelFile)
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/import/${PRICE_LISTS_API}/${priceListId}/priced-items`, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          // handleError(error)
          reject(error)
        })
    })
  },
  importPriceSheetItems({ getters }, { excelFile }) {
    const formData = new FormData()
    formData.append('file', excelFile)
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/import`, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          // handleError(error)
          reject(error)
        })
    })
  },
  importAllPriceSheets({ getters }, { priceSheetId, excelFile }) {
    const formData = new FormData()
    formData.append('file', excelFile)
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API_NEW}/${priceSheetId}/import`, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          // handleError(error)
          reject(error)
        })
    })
  },
  fetchPriceListItems({ getters, commit }, { priceListId, pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${priceListId}/priced-items?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_PRICE_LIST_ITEMS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchPriceSheetItems({ getters, commit }, { priceListId, pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${PRICE_SHEET_API}/${priceListId}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_PRICE_SHEET_ITEMS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchCostSheetItems({ getters, commit }, { priceListId, pageInfo, sortInfo, searchText = '', shouldCommitToStore = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${COST_SHEET_API}/${priceListId}?${query}`)
        .then(({ data }) => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_COST_SHEET_ITEMS, data.items)
          }
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updatePriceListItem({ getters }, { priceListItem }) {
    return new Promise((resolve, reject) => {
      if (priceListItem.listPrice) {
        getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${priceListItem.priceListId}/priced-items/${priceListItem.optionId}/list-price/${priceListItem.listPrice}`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } else {
        getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${priceListItem.priceListId}/priced-items/${priceListItem.optionId}`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  updatePriceSheetItem({ getters }, { priceListId, payload }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${PRICE_SHEET_API}/${priceListId}`, payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateCostSheetItem({ getters }, { priceListItem, payload }) {
    return new Promise((resolve, reject) => {
      if (priceListItem.listPrice) {
        getMasterDataClient().put(`tenants/${getters.getUserTenantId}/${COST_SHEET_API}/${priceListItem.priceListId}`, payload)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } else {
        getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${PRICE_LISTS_API}/${priceListItem.priceListId}/priced-items/${priceListItem.optionId}`)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  // IMAGES
  uploadImage({ getters }, imageFile) {
    const formData = new FormData()
    const image = new Blob([imageFile], { type: imageFile.type })
    image.name = imageFile.name
    formData.append('file', image, imageFile.name.toLowerCase())
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${IMAGES_API}`, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error.response.data)
        })
    })
  },
  deleteImage({ getters }, { imageId }) {
    getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${IMAGES_API}/${imageId}`).catch(error => {
      handleError(error)
    })
  },
  getImage({ getters }, { imageId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${IMAGES_API}/${imageId}`, {
        responseType: 'blob'
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // GROUPS
  fetchGroups({ getters, commit }, { pageInfo, sortInfo, searchText = '', shouldCommitToStore = true, includeEmptyGroups = true }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    queryObject.includeEmptyGroups = includeEmptyGroups
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${GROUPS_API}?${query}`)
        .then(response => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_GROUPS, response.data.items)
          }
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchGroupsLight({ getters, commit }, modelType) {
    return new Promise((resolve, reject) => {
      getMasterDataClient(2.0).get(`tenants/${getters.getUserTenantId}/${GROUPS_API}/light` + (modelType ? `?modelType=${modelType}` : ''))
        .then(response => {
          commit(MASTER_DATA_SET_GROUPS, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchGroupByType({ getters, commit }, pricedItemType, shouldCommitToStore = true) {
    return new Promise((resolve, reject) => {
      getMasterDataClient(2.0).get(`tenants/${getters.getUserTenantId}/groups/basic/${pricedItemType}`)
        .then(response => {
          if (shouldCommitToStore) {
            commit(MASTER_DATA_SET_GROUPS, response.data)
          }
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchGroupIds({ getters }, { searchText = null }) {
    const query = searchText ? 'searchText=' + searchText : ''
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/${GROUPS_API}/ids?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteGroup({ getters }, { groupId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().delete(`tenants/${getters.getUserTenantId}/${GROUPS_API}/${groupId}`)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  deleteGroups({ getters }, { groupIds }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().post(`tenants/${getters.getUserTenantId}/${GROUPS_API}/delete-many`, groupIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  fetchGroupById({ getters, commit }, { groupId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient(2.0).get(`tenants/${getters.getUserTenantId}/${GROUPS_API}/${groupId}`)
        .then(response => {
          commit(MASTER_DATA_SET_CURRENT_GROUP, response.data)
          resolve(response.data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  addGroup({ getters }, { group, ignoreErrorCodes = [] }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient(2.0)
        .post(`tenants/${getters.getUserTenantId}/${GROUPS_API}`, group)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors &&
            !ignoreErrorCodes.includes(error.response.data.errors[0].code)) {
            handleError(error)
          }
          reject(error)
        })
    })
  },
  editGroup({ getters }, { group }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient(2.0)
        .put(`tenants/${getters.getUserTenantId}/${GROUPS_API}/${group.id}`, group)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  assignOptionsToGroup({ getters }, { groupId, optionIds }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/groups/${groupId}/assign-options`, optionIds)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // COLUMN SETTINGS
  fetchColumnSettings({ commit, getters }, { userId }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/user/${userId}/${COLUMNS_SETTINGS_API}/PricedItem`)
        .then(({ data }) => {
          commit(MASTER_DATA_SET_COLUMN_SETTINGS, data)
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  updateColumnSettings({ getters }, { userId, columnSettings }) {
    return new Promise((resolve, reject) => {
      getMasterDataClient().put(`tenants/${getters.getUserTenantId}/user/${userId}/${COLUMNS_SETTINGS_API}/PricedItem`, columnSettings)
        .then(() => {
          resolve()
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  },
  // ENTITY CHANGE HISTORY
  fetchEntityChangeHistory({ getters }, { entity, entityId, pageInfo, sortInfo, searchText = '' }) {
    let queryObject = {}
    if (pageInfo && pageInfo.pageIndex) queryObject.PageNumber = pageInfo.pageIndex
    if (pageInfo && pageInfo.pageSize) queryObject.PageSize = pageInfo.pageSize
    if (sortInfo && sortInfo.columnName) queryObject.SortBy = sortInfo.columnName
    if (sortInfo && sortInfo.order) queryObject.SortDirection = sortInfo.order
    if (searchText) queryObject.searchText = searchText
    const query = Query.qs(queryObject)
    return new Promise((resolve, reject) => {
      getMasterDataClient().get(`tenants/${getters.getUserTenantId}/entities/${entity}/${entityId}/changes?${query}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          handleError(error)
          reject(error)
        })
    })
  }
}
